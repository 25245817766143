<script>
import { menuConfig } from '@/utils/menuConfig'
import dashboardIcon from '@/assets/images/dashboard-icon.svg'

export default defineComponent({
  props: ['isSideBarActive'],
  emits: ['changeSideBarState'],
  async setup(props) {
    const isSideBarActive = ref()
    const route = useRoute()

    // mapping menu logic
    const activeLob = useAuth().activeLob
    const responseMenu = await useAuth().checkToken()
    const resultMenu = responseMenu.data?.menu || []

    const checkPrivilageSameAsLob = (item, privilageList) => {
      return item.privilege
        .filter(privilege => privilege.is_sidebar_menu && privilege.is_cms)
        .some(privilege =>
          privilege.lob_id === activeLob && privilageList.some(activePriv => activePriv.privilege_id === privilege.privilege_id),
        )
    }

    const mappingMenu = resultMenu.map((menu) => {
      const activePrivilage = menu.privilege.filter(privilage => privilage.is_cms)

      const data = menuConfig.filter((item) => {
        // checking if privilage in active menu is same as active lob
        const isPrivilageSameAsLob = checkPrivilageSameAsLob(item, activePrivilage)

        return (item.menu_id === menu.menu_id && menu.is_active === true && isPrivilageSameAsLob && !item.has_children)
      })

      return data.shift()
    }).filter(menu => menu !== undefined)

    const dashboardPath = ref('/')

    // mapping expandable menu
    const getExpandedMenu = (menu, item) => {
      const expandableMenu = {
        menu_name: menu.menu_name,
        font_icon: menu.font_icon,
        image_icon: menu.image_icon,
        fe_route: menu.privilege?.find(privilege => privilege.is_sidebar_menu && privilege.lob_id === activeLob)?.fe_route || menu.fe_route,
        is_active: menu.is_active,
        menu_id: menu.menu_id,
        children: [],
      }
      menu.privilege.forEach((child) => {
        const checkPrivilege = item.privilege.find((privilege) => {
          return privilege.privilege_id === child.privilege_id && privilege.is_cms && privilege.lob_id === activeLob && child.is_sidebar_menu
        })
        if (checkPrivilege && child.is_children) {
          expandableMenu.children.push({
            path: child.fe_route,
            name: child.privilege_name,
          })
        }
      })
      return expandableMenu
    }

    menuConfig.forEach((menu) => {
      if (menu.has_children) {
        resultMenu.find((item) => {
          const activePrivilage = item.privilege.filter(privilage => privilage.is_cms)
          const isPrivilageSameAsLob = checkPrivilageSameAsLob(menu, activePrivilage)

          if (item.menu_id === menu.menu_id && item.is_active && isPrivilageSameAsLob) {
            const expandableMenu = getExpandedMenu(menu, item)
            return mappingMenu.push(expandableMenu)
          }
          else {
            return false
          }
        })
      }
    })

    // mapping dashboard
    mappingMenu.unshift(
      {
        is_active: true,
        menu_id: 4,
        menu_name: 'Dashboard',
        image_icon: dashboardIcon,
        fe_route: '/',
      },
    )

    const menus = reactive(mappingMenu.length > 0
      ? mappingMenu.map((entry) => {
        if (entry.menu_name === 'Dashboard') {
          return {
            title: entry.menu_name,
            imgIcon: entry.image_icon,
            fontIcon: entry.font_icon,
            path: dashboardPath.value,
            isActive: route.fullPath.replace('/', '') === entry.fe_route,
            isShow: entry.is_active,
            children: entry.children,
          }
        }
        else {
          return {
            title: entry.menu_name,
            imgIcon: entry.image_icon,
            fontIcon: entry.font_icon,
            path: entry.privilege?.find(privilege => privilege.is_sidebar_menu && privilege.lob_id === activeLob)?.fe_route || entry.fe_route,
            isActive: route.fullPath.replace('/', '') === entry.fe_route,
            isShow: entry.is_active,
            children: entry.children,
          }
        }
      })
      : [
          {
            title: 'Dashboard',
            imgIcon: dashboardIcon,
            path: dashboardPath.value,
            isActive: true,
            isShow: true,
          },
        ],
    )
    // end of mapping menu logic

    watchEffect(() => {
      isSideBarActive.value = props.isSideBarActive
    })

    const toggleSubMenu = (menu) => {
      menu.expanded = !menu.expanded
      menu.isActive = route.fullPath.startsWith(menu.path)
    }

    return {
      menus,
      isSideBarActive,
      toggleSubMenu,
      route,
    }
  },
})
</script>

<template>
  <ul>
    <template v-for="menu in menus" :key="menu.title">
      <li v-if="menu.isShow" class="pb-1">
        <template v-if="!menu.children || menu.children.length === 0">
          <nuxt-link
            :to="menu.path"
            class="flex items-center pl-5 py-5 text-white rounded-lg hover:bg-[#4670E7] menu-item"
            :class="{ 'bg-[#4670E7]': route.fullPath === menu.path, 'menu-active': route.fullPath === menu.path }"
            @click="$emit('changeSideBarState', true)"
          >
            <img v-if="menu.imgIcon" alt="menu-icon" class="icon-sidebar" :src="menu.imgIcon">
            <Icon v-else-if="menu.fontIcon" :name="menu.fontIcon" class="w-5 h-5" />
            <span :class="{ hidden: !isSideBarActive }" class="ml-3">{{ menu.title }}</span>
          </nuxt-link>
        </template>
        <template v-else>
          <a
            href="#"
            class="flex items-center pl-5 py-5 text-white rounded-lg hover:bg-[#4670E7] justify-between menu-parent"
            @click.prevent="toggleSubMenu(menu)"
            @click="$emit('changeSideBarState', true)"
          >
            <span class="flex">
              <img v-if="menu.imgIcon" alt="menu-icon" class="icon-sidebar" :src="menu.imgIcon">
              <Icon v-else-if="menu.fontIcon" :name="menu.fontIcon" class="w-5 h-5" />
              <span :class="{ hidden: !isSideBarActive }" class="ml-3">{{ menu.title }}</span>
            </span>
            <Icon
              v-if="isSideBarActive"
              :name="menu.expanded ? 'material-symbols:keyboard-arrow-up' : 'material-symbols:keyboard-arrow-down'"
              size="24"
              class="mt-1 mr-2"
            />
          </a>
          <ul v-if="isSideBarActive && menu.expanded" class="py-2 space-y-2 menu-sub">
            <li v-for="child in menu.children" :key="child.path">
              <nuxt-link
                :to=" child.path"
                :class="{ 'bg-[#4670E7]': route.fullPath === child.path }"
                class="flex items-center  pl-10 py-5 text-white rounded-lg hover:bg-[#4670E7] menu-item"
              >
                <span class="ml-3">{{ child.name }}</span>
              </nuxt-link>
            </li>
          </ul>
        </template>
      </li>
    </template>
  </ul>
</template>

<style scoped>
.icon-sidebar {
  width: 20px;
}
</style>
