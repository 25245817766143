<script>
import { storeToRefs } from 'pinia'

export default defineComponent({
  setup() {
    const isActive = ref(true)
    const authStore = useAuth()
    const showMenu = ref(true)

    function toggleAside() {
      if (isActive.value)
        isActive.value = false

      else
        isActive.value = true
    }

    function updateSideBarState(value) {
      isActive.value = value
    }

    function handleResize() {
      const screenWidth = window.innerWidth
      if (screenWidth <= 1188)
        isActive.value = false
      else
        isActive.value = true
    }

    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    const { activeLob } = storeToRefs(authStore)
    watch(activeLob, () => {
      // re-render menu component
      showMenu.value = false
      setTimeout(() => showMenu.value = true)
    })

    return {
      toggleAside,
      isActive,
      updateSideBarState,
      showMenu,
    }
  },
})
</script>

<template>
  <div
    class="relative flex-shrink-0 transition-all transform w-80"
    :class="{ 'translate-x-0 w-[90px]': !isActive }"
  >
    <aside
      id="default-sidebar"
      class="top-0 left-0 h-screen z-40 bg-[#4782FC]"
      aria-label="Sidebar"
    >
      <div class="h-full overflow-y-auto sidebar-content">
        <div class="flex items-center ml-9 py-7">
          <img
            src="../assets/images/Logo-Sally.svg"
            :class="{ hidden: !isActive }"
            alt=""
            srcset=""
          >
        </div>
        <hr class="mx-5">
        <div class="px-4 py-4 ">
          <ul class="space-y-2 font-medium">
            <Menus
              v-if="showMenu"
              :is-side-bar-active="isActive"
              @change-side-bar-state="updateSideBarState"
            />
          </ul>
        </div>
      </div>
    </aside>
    <button
      id="toggle-aside"
      type="button"
      class="text-white bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg shadow-lg hover:ring-4 hover:ring-blue-300 text-sm px-2 py-2.5 text-center inline-flex items-center absolute right-[-20px] top-[15px]"
      @click="toggleAside()"
    >
      <Icon v-if="isActive" name="material-symbols:chevron-left" size="24" color="#4782FC" />
      <Icon v-else name="material-symbols:chevron-right" size="24" color="#4782FC" />
    </button>
  </div>
</template>

<style scoped>
/* #default-sidebar {
  transition: min-width 100ms ease-in-out;
} */
</style>
